import classNames from 'classnames';
import IconCalendarSVG from '@assets/new/icon-calendar.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  color?: 'black' | 'blue' | 'gray' | 'purple' | 'white';
  size?: 'lg' | 'md';
  withFill?: boolean;
};

const IconCalendar = ({
  color = 'gray',
  size = 'md',
  withFill = true,
}: IconProps) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      <IconCalendarSVG
        className={classNames('icon-calendar', `color-${color}`, {
          'no-fill': !withFill,
        })}
        style={style}
      />
      <style jsx>{styles}</style>
    </>
  );
};

export default IconCalendar;
