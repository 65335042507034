import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { desktop, mobile } = BREAKPOINT;

const styles = css`
  .modal-container {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 32px;
    z-index: 110; /* Needs to be higher than .header */
    @media screen and (max-width: ${mobile}px) {
      padding: 0;
    }
    &.absolute {
      @media screen and (min-width: ${desktop}px) {
        padding: 0;
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        height: auto;
      }
    }
    .modal {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0 auto;
      max-height: 100%;
      color: ${COLORS.black900};
      background: #fff;
      border-radius: 8px;
      z-index: 2;
      overflow-y: hidden;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
      @media screen and (max-width: ${mobile}px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
    .modal-header {
      position: relative;
      padding: 24px;
      @media screen and (max-width: ${mobile}px) {
        padding: 0;
        position: fixed;
        width: 100%;
        z-index: 1;
      }
      & .header-right-cont {
        position: absolute;
        top: 4px;
        right: 4px;
        @media screen and (min-width: ${desktop}px) {
          top: 24px;
          right: 24px;
        }
        display: flex;
        .close-button {
          width: 24px;
          height: 24px;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
          @media screen and (max-width: ${mobile}px) {
            width: 48px;
            height: 48px;
          }
        }
      }
      .header-left-button {
        position: absolute;
        left: 16px;
        top: 16px;
        @media screen and (min-width: ${desktop}px) {
          top: 24px;
        }
        :global(button:not(:disabled)) {
          cursor: pointer;
        }
      }
    }
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
      @media screen and (max-width: ${mobile}px) {
        margin: 16px 0 24px;
        font-size: 20px;
        text-align: left;
      }
    }
    .modal-contents {
      display: flex;
      flex: 1;
      height: 100%;
      overflow-y: scroll;
      @media screen and (max-width: ${mobile}px) {
        flex-direction: column;
        padding: 48px 16px 24px;
        max-height: calc(100% - 72px);
        max-height: 100%;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-top: 1px solid #efeff0;
      background-color: ${COLORS.white};
      .button-link {
        font-weight: bold;
        font-size: 14px;
        color: ${COLORS.primary};
        cursor: pointer;
      }
      :global(> .btn) {
        margin: 0;
      }
      :global(.center-button) {
        margin: 0 auto;
      }
    }
    .background {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      &.show {
        display: block;
      }
    }
  }
`;

export default styles;
