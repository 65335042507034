import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const { desktopL } = BREAKPOINT;

const styles = css`
  .input-stepper {
    width: 320px;
    padding: 20px 28px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: ${desktopL}px) {
      padding: 2px 16px 0;
      width: 100%;
      min-height: 50px;
    }
    .input-stepper__container {
      display: flex;
    }
    button {
      width: 24px;
      height: 24px;
      padding: 0 4px;
      cursor: pointer;
      @media screen and (max-width: ${desktopL}px) {
        border: none;
      }
    }
    button:nth-of-type(1) {
      @media screen and (max-width: ${desktopL}px) {
        margin-right: 24px;
      }
    }
    button:disabled {
      opacity: 0.6;
    }
  }
  .input-stepper-value {
    margin: 0 16px;
  }
`;

export default styles;
