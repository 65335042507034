import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  :global(.icon-multi-user.no-fill) {
    :global(path:first-child) {
      opacity: 0;
    }
  }
  :global(.icon-multi-user.color-black900) {
    :global(path) {
      fill: ${COLORS.black900};
    }
  }
  :global(.icon-multi-user.color-gray) {
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
  :global(.icon-multi-user.color-blue) {
    :global(path) {
      fill: ${COLORS.primary};
    }
  }
`;

export default styles;
